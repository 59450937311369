import { selectSubsidiariesState } from '../index';
import { createSelector } from '@ngrx/store';
import { SubsidiariesState } from '../reducers/subsidiaries.reducer';

export const selectSubsidiaries = createSelector(
  selectSubsidiariesState,
  (state: SubsidiariesState) => state && state.subsidiaries
);
export const selectSubsidiariesLoading = createSelector(
  selectSubsidiariesState,
  (state: SubsidiariesState) => state && state.loading
);
export const selectSubsidiariesError = createSelector(
  selectSubsidiariesState,
  (state: SubsidiariesState) => state && state.error
);

// export const selectAllHubsSorted = createSelector(selectAllHubs, (hubs) => {
//   if (hubs) {
//     return hubs;
//   } else {
//     return [];
//   }
// });

// export const selectAllHubsCountries = createSelector(
//   selectHubsState,
//   (state: HubsState) =>
//     state &&
//     state.hubs &&
//     state.hubs.map((hub) => hub.country).filter((value, index, self) => self.indexOf(value) === index)
// );

// export const selectHubsCitiesByCountry = (
//   country: string
// ): MemoizedSelector<object, string[] | null, (s1: HubsState) => string[] | null> =>
//   createSelector(
//     selectHubsState,
//     (state: HubsState) =>
//       state &&
//       state.hubs &&
//       state.hubs
//         .filter((hub) => hub.country === country)
//         .map((hub) => hub.city)
//         .filter((value, index, self) => self.indexOf(value) === index)
//   );

// export const selectHubsByCountry = (
//   country: string
// ): MemoizedSelector<object, Hub[] | null, (s1: HubsState) => Hub[] | null> =>
//   createSelector(
//     selectHubsState,
//     (state: HubsState) => state && state.hubs && state.hubs.filter((hub) => hub.country === country)
//   );

// export const selectHubsByCity = (
//   city: string
// ): MemoizedSelector<object, Hub[] | null, (s1: HubsState) => Hub[] | null> =>
//   createSelector(
//     selectHubsState,
//     (state: HubsState) => state && state.hubs && state.hubs.filter((hub) => hub.city === city)
//   );

// export const selectHubNameById = (
//   id: string
// ): MemoizedSelector<object, string | null | undefined, (s1: HubsState) => string | null | undefined> =>
//   createSelector(
//     selectHubsState,
//     (state: HubsState) => state && state.hubs && state.hubs.find((hub) => hub.id === id)?.name
//   );
